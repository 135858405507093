import { render, staticRenderFns } from "./HeaderClickReportsConsolidated.vue?vue&type=template&id=2b60a49e"
import script from "./HeaderClickReportsConsolidated.vue?vue&type=script&lang=js"
export * from "./HeaderClickReportsConsolidated.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QTooltip from 'quasar/src/components/tooltip/QTooltip.js';
import QIntersection from 'quasar/src/components/intersection/QIntersection.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QToolbarTitle from 'quasar/src/components/toolbar/QToolbarTitle.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QSelect from 'quasar/src/components/select/QSelect.js';
import QCheckbox from 'quasar/src/components/checkbox/QCheckbox.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QToolbar,QBtn,QTooltip,QIntersection,QForm,QCard,QToolbarTitle,QCardSection,QSelect,QCheckbox});
